import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import About from '../components/About.vue';
import Other from '../components/Other.vue';
import WebGuide from '../components/WebGuide.vue';
import Contact from '../components/Contact.vue';

import SalesWebsite from '../components/ServicesDetail/SalesWebsite.vue';
import LandingDesignWebsite from '../components/ServicesDetail/LandingDesignWebsite.vue';
import EcomSiteWebsite from '../components/ServicesDetail/EcomSiteWebsite.vue';
import BlogDesignWebsite from '../components/ServicesDetail/BlogDesignWebsite.vue';
import StudentHelp from '../components/ServicesDetail/StudentHelp.vue';
import AdServices from '../components/ServicesDetail/AdServices.vue';

import WorkingProcess from '@/components/WorkingProcess.vue';
import NotFound from '@/components/404NotFound.vue';

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
        title: 'Trang chủ - Thiết kế website chuẩn SEO',
        description: 'Thiết kế website chuyên nghiệp, chuẩn SEO, tốc độ tải trang nhanh, giao diện thân thiện.'        
    }
  },
  {
    path: '/about',
    component: About,
    meta: {
      title: 'Giới thiệu - Dịch vụ thiết kế website',
      description: 'Tìm hiểu về dịch vụ thiết kế website chuyên nghiệp, tối ưu SEO và hiệu suất cao.'
    }
  },
  {
    path: '/contact',
    component: Contact,
    meta: {
      title: 'Liên hệ - Thiết kế website',
      description: 'Liên hệ với chúng tôi để nhận tư vấn về thiết kế website và tối ưu SEO.'
    }
  },
  {
    path: '/web-guide',
    component: WebGuide,
    meta: {
      title: 'Cẩm nang thiết kế website',
      description: 'Hướng dẫn chi tiết về quy trình thiết kế website tối ưu SEO và hiệu suất.'
    }
  },
  {
    path: '/social-services',
    component: Other,
    meta: {
      title: 'Dịch vụ mạng xã hội',
      description: 'Chạy quảng cáo Facebook Ads, tăng tương tác các dịch vụ mạng xã hội như Facebook, Tiktok, Thread.'
    }
  },
  {
    path: '/thiet-ke-website-ban-hang-ca-nhan',
    component: SalesWebsite,
    meta: {
      title: 'Thiết kế website bán hàng cá nhân',
      description: 'Dịch vụ thiết kế website bán hàng cá nhân chuyên nghiệp và hiệu quả.'
    }
  },
  {
    path: '/thiet-ke-website-landing-page',
    component: LandingDesignWebsite,
    meta: {
      title: 'Thiết kế website Landing Page',
      description: 'Landing Page chuyên nghiệp, tối ưu chuyển đổi và phù hợp với nhiều ngành nghề.'
    }
  },
  {
    path: '/thiet-ke-website-thuong-mai-dien-tu',
    component: EcomSiteWebsite,
    meta: {
      title: 'Thiết kế website thương mại điện tử',
      description: 'Thiết kế website thương mại điện tử chuyên nghiệp, hỗ trợ bán hàng và quản lý dễ dàng.'
    }
  },
  {
    path: '/thiet-ke-website-blog',
    component: BlogDesignWebsite,
    meta: {
      title: 'Thiết kế website Blog cá nhân',
      description: 'Dịch vụ thiết kế blog cá nhân, tối ưu SEO, và giao diện độc đáo.'
    }
  },
  {
    path: '/thiet-ke-do-an-website',
    component: StudentHelp,
    meta: {
        title: 'Thiết kế đồ án website cho sinh viên',
        description: 'Hỗ trợ thiết kế và xây dựng đồ án website dành cho sinh viên.'        
    }
  },
  {
    path: '/chay-quang-cao-website',
    component: AdServices,
    meta: {
      title: 'Dịch vụ chạy quảng cáo website',
      description: 'Dịch vụ chạy quảng cáo website hiệu quả, tăng doanh số và lưu lượng truy cập.'
    }
  },
  {
    path: '/quy-trinh-thiet-ke-website',
    component: WorkingProcess,
    meta: {
      title: 'Quy trình thiết kế website chuyên nghiệp',
      description: 'Chi tiết từng bước quy trình thiết kế website, từ ý tưởng đến hoàn thiện.'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
        title: '404 - Trang không tìm thấy',
        description: 'Trang bạn tìm kiếm không tồn tại. Vui lòng kiểm tra lại đường dẫn hoặc quay về trang chủ.'        
    }
  }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Click vào một đường link trong Vue Router và trang tự động cuộn lên đầu trang
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
});

// Thêm logic cập nhật meta title và description
router.afterEach((to) => {
  // Cập nhật title
  document.title = to.meta.title || 'Default Title';

  // Cập nhật meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || 'Default description');
  } else {
    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = to.meta.description || 'Default description';
    document.head.appendChild(meta);
  }

   // Cập nhật thẻ canonical
   const canonical = document.querySelector('link[rel="canonical"]');
   if (canonical) {
     canonical.setAttribute('href', window.location.href);
   } else {
     const link = document.createElement('link');
     link.rel = 'canonical';
     link.href = window.location.href;
     document.head.appendChild(link);
   }
});

export default router;
